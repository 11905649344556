<template>
  <v-app id="app" :dark="theme.dark" :light="!theme.dark">
    <v-progress-linear
      style="position: fixed; z-index: 4"
      v-show="$store.state.loading"
      color="primary"
      class="ma-0"
      :height="4"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container
      v-if="!initialized && $route.name !== 'login'"
      :style="{
        background: !theme.dark ? 'white' : '',
      }"
      style="
        position: fixed;
        z-index: 3;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      "
      fill-height
    >
      <v-layout align-center justify-center>
        <v-img
          contain
          aspect-ratio="1"
          v-if="theme.logoImage"
          :src="root + 'media' + theme.logoImage"
          :lazy-src="root + 'media' + theme.logoImage"
          max-width="360"
          max-height="360"
        ></v-img>
      </v-layout>
    </v-container>

    <v-navigation-drawer
      v-show="show"
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-if="$route.name !== 'login' && initialized"
      v-model="drawer"
      app
      expand
      :style="{
        'z-index': 15,
      }"
    >
      <v-card flat class="pa-3">
        <v-img
          v-if="theme.logoImage"
          height="80px"
          style="max-width: 100%"
          contain
          :src="root + 'media' + theme.logoImage"
          :lazy-src="root + 'media' + theme.logoImage"
        ></v-img>
        <v-img
          style="max-width: 100%"
          v-else-if="skinData && skinData.header && skinData.header.logo"
          height="80px"
          contain
          :lazy-src="
            $helpers.lodash.isUndefined(skinData.header)
              ? ''
              : $config.managerMedia + skinData.header.logo
          "
          :src="
            $helpers.lodash.isUndefined(skinData.header)
              ? ''
              : $config.managerMedia + skinData.header.logo
          "
        ></v-img>
      </v-card>
      <v-divider></v-divider>
      <v-list nav v-if="userMenuOpen">
        <v-list-item
          @click.stop="logout"
          v-if="
            isCheckedIn &&
            authResult &&
            authResult.checkin &&
            authResult.checkin[0] &&
            mode !== 'permanent-only'
          "
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t("login.logout") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon class="pr-1">mdi-exit-to-app</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!--logout works the same as signin so no additional method needed -->
        <v-list-item @click.stop="logout" v-else-if="mode !== 'permanent-only'">
          <v-list-item-content>
            <v-list-item-title>{{ $t("login.signin") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon class="pr-1">mdi-arrow-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list nav v-if="mainMenu.length > 0 && !userMenuOpen">
        <v-list-group
          v-for="(item, index) in mainMenu"
          v-if="item.submenu"
          v-model="item.active"
          :key="index + item.title"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <template v-for="(subItem, subIndex) in item.submenu">
            <link-prevue
              v-if="isUrl(subItem.title)"
              :key="subIndex + subItem.title"
              :url="subItem.title"
            >
              <template slot-scope="props">
                <v-list-item
                  @click="goTo(props.url)"
                  :key="subIndex + subItem.title"
                >
                  <v-list-item-avatar v-if="subItem.foreground_image">
                    <img
                      :src="$config.managerMedia + subItem.foreground_image"
                    />
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else-if="props.img">
                    <img :src="props.img" />
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else-if="getRouteIcon(subItem, mode)">
                    <v-icon>{{ getRouteIcon(subItem, mode) }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ props.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </link-prevue>
            <v-list-item
              v-else
              :to="getRoute({ item: subItem, mode })"
              :key="subIndex + subItem.title"
            >
              <v-list-item-avatar v-if="subItem.foreground_image">
                <img :src="$config.managerMedia + subItem.foreground_image" />
              </v-list-item-avatar>
              <v-list-item-avatar v-else-if="getRouteIcon(subItem, mode)">
                <v-icon>{{ getRouteIcon(subItem, mode) }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item link v-else :to="getRoute({ item, mode })">
          <v-list-item-avatar v-if="item.foreground_image">
            <img :src="$config.managerMedia + item.foreground_image" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else-if="index === 0">
            <v-icon>mdi-home</v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar v-else-if="getRouteIcon(item, mode)">
            <v-icon>{{ getRouteIcon(item, mode) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title @click="home">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- as currently the navigation drawer is not properly working on android and ios (too short) this is a hack -->
      <div style="padding-bottom: 25vh"></div>
    </v-navigation-drawer>
    <!-- FOR ATLANTIS Transparent app bar on landingpage -->
    <!--<v-app-bar
      :fixed="this.$route.params.cpId === this.landingPageId"
      v-show="show"
      v-if="$route.name !== 'login' && initialized"
      :dark="theme.toolbarDark"
      :light="!theme.toolbarDark"
      :app="this.$route.params.cpId !== this.landingPageId"
      :style="{
        'background-color':
          this.$route.params.cpId === this.landingPageId
            ? 'transparent'
            : theme.toolbarBackground,
        'z-index': 10,
      }"
      clipped-left
      clipped-right
      elevate-on-scroll
    > -->
    <v-app-bar
      :class="{
        'app-bar-white': whiteToolbar,
      }"
      v-show="show"
      v-if="$route.name !== 'login' && initialized"
      :dark="theme.toolbarDark"
      :light="!theme.toolbarDark"
      app
      :style="{
        'background-color': theme.toolbarBackground,
        'z-index': 10,
      }"
      clipped-left
      clipped-right
      flat
      tile
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title @click="home">{{ theme.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="tourAvailable" @click="startTour()" icon>
        <v-icon>mdi-help</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="$route.name === 'browse' && allMovies.total > 0"
        @click="setShowFilter({ state: true })"
        data-v-step="browse_0"
      >
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
      <template
        v-if="$route.name === 'browse' || $route.name === 'packages'"
        v-slot:extension
      >
        <v-tabs
          grow
          show-arrows
          background-color="transparent"
          color="primary"
          v-model="tab"
        >
          <v-tab data-v-step="browse_1" :to="{ name: 'browse' }">{{
            $t("vod.browse")
          }}</v-tab>
          <v-tab data-v-step="browse_2" :to="{ name: 'packages' }">{{
            $t("vod.packages")
          }}</v-tab>
        </v-tabs>
      </template>
      <v-btn
        icon
        v-if="
          $route.name !== 'search' &&
          mode !== 'walkin' &&
          mode !== 'walkin-only'
        "
        :to="{ name: 'search' }"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-menu v-model="userMenuOpen" offset-y :close-on-content-click="false">
        <!--<checked-in-person-avatar slot="activator"></checked-in-person-avatar>-->
        <template v-slot:activator="{ on: { click } }">
          <v-btn @click="click((userMenuOpen = true))" icon>
            <v-icon v-if="!userMenuOpen">mdi-dots-vertical</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list
            v-if="
              authResult.checkin && mode !== 'walkin' && mode !== 'walkin-only'
            "
            class="pa-0"
            light
          >
            <signed-in-user-list-tile></signed-in-user-list-tile>
          </v-list>

          <!--<v-divider v-if="tvMessageUnreadCount > 0 || cartQuantity > 0"></v-divider>

            <template v-if="mode !== 'walkin' && mode !== 'walkin-only'">
              <v-list v-if="tvMessageUnreadCount > 0 || cartQuantity > 0 || tvMessages.length > 0">
                <v-list-item
                  v-if="tvMessageUnreadCount > 0 || tvMessages.length > 0"
                  :to="{name: 'tvMessage'}"
                >
                  <v-list-item-avatar>
                    <v-badge v-if="tvMessageUnreadCount > 0" overlap color="primary">
                      <span class="text-caption" slot="badge">{{tvMessageUnreadCount}}</span>
                      <v-icon>mail_outline</v-icon>
                    </v-badge>
                    <v-icon v-else>mail_outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{$t("tvmessages.header")}}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="cartQuantity > 0" :to="{name: 'shopCart'}">
                  <v-list-item-avatar>
                    <v-badge overlap color="primary">
                      <span class="text-caption" slot="badge">{{cartQuantity}}</span>
                      <v-icon>shopping_cart</v-icon>
                    </v-badge>
                  </v-list-item-avatar>
                  <v-list-item-title>{{$t("shop.cart")}}</v-list-item-title>
                </v-list-item>
              </v-list>
          </template>-->
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-translate</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <language-selection
                  @change="() => (this.userMenuOpen = false)"
                  :solo="true"
                ></language-selection>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item && !item.divider"
              v-for="(item, index) in menu"
              :key="index"
              @click="item.click"
            >
              <v-list-item-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="$route.query.lang === 'de'">
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'terms' }">
                <v-list-item-avatar>
                  <v-icon>mdi-file-document-box-multiple-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-regular">{{
                    $t("general.termsAndConditions")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'privacy' }">
                <v-list-item-avatar>
                  <v-icon>mdi-incognito</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-regular">{{
                    $t("general.privacyPolicy")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>
      <!--<v-btn
          v-if="tvMessageUnreadCount > 0"
          :to="{name: 'tvMessage'}"
          style="min-width:48px;"
          flat
        >
          <v-badge overlap color="primary">
            <span class="text-caption" slot="badge">{{tvMessageUnreadCount}}</span>
            <v-icon medium>mail_outline</v-icon>
          </v-badge>
      </v-btn>-->
      <!--<v-btn v-if="cartQuantity > 0" :to="{name: 'shopCart'}" style="min-width:48px;" flat>
          <v-badge overlap color="primary">
            <span class="text-caption" slot="badge">{{cartQuantity}}</span>
            <v-icon medium>shopping_cart</v-icon>
          </v-badge>
      </v-btn>-->
    </v-app-bar>
    <!-- margin-bottom for the "jump to top button" -->

    <!-- Page tutorial -->
    <v-tour name="myTour" :steps="steps" :options="options">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.currentStep === index"
            v-for="(step, index) of tour.steps"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="true"
          >
            <!-- Custom actions for tutorial in RoomService view -->
            <template
              v-if="
                $route.path === '/demo/room-control' &&
                tour.currentStep === tour.steps.length - 3
              "
            >
              <div slot="actions">
                <button @click="tour.stop" class="v-step__button">
                  {{ $t("tour.skip") }}
                </button>
                <button @click="tour.previousStep" class="v-step__button">
                  {{ $t("tour.prev") }}
                </button>
                <button
                  @click="activateSchedulerSwitch(tour.nextStep)"
                  class="v-step__button"
                >
                  {{ $t("tour.next") }}
                </button>
              </div>
            </template>
            <!-- template tag below needed to translate vue-tour action buttons -->
            <template v-else>
              <div slot="actions">
                <button
                  @click="tour.stop"
                  v-if="!tour.isLast"
                  class="v-step__button"
                >
                  {{ $t("tour.skip") }}
                </button>
                <button
                  @click="tour.previousStep"
                  v-if="!tour.isFirst"
                  class="v-step__button"
                >
                  {{ $t("tour.prev") }}
                </button>
                <button
                  @click="tour.nextStep"
                  v-if="!tour.isLast"
                  class="v-step__button"
                >
                  {{ $t("tour.next") }}
                </button>
                <button
                  @click="tour.stop"
                  v-if="tour.isLast"
                  class="v-step__button"
                >
                  {{ $t("tour.finish") }}
                </button>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-main v-show="show">
      <vue-page-transition style="height: 100%" name="fade">
        <router-view
          v-if="$store.state.session.initialized || $route.name === 'login'"
        ></router-view>
      </vue-page-transition>
    </v-main>
    <v-dialog max-width="400" v-model="showCheckoutConfirmation">
      <v-card>
        <v-card-title>{{ $t("modal.confirmCheckoutTitle") }}</v-card-title>
        <v-card-text>{{ $t("modal.confirmCheckout") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showCheckoutConfirmation = false"
            >{{ $t("modal.cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="goToWebcheckout(), (showCheckoutConfirmation = false)"
            >{{ $t("modal.continue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" v-model="showDemoCheckoutDone">
      <v-card>
        <v-card-title>
          {{ $t("modal.demoCheckoutConfirmationTitle") }}
        </v-card-title>
        <v-card-text>
          {{ $t("modal.demoCheckoutConfirmationDescription") }}
          <br />
          <br />
          {{ $t("modal.demoCheckoutConfirmationRatingDescription") }}
          <div class="text-center mt-12">
            <v-rating
              v-model="rating"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              half-increments
              hover
            ></v-rating>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="showDemoCheckoutDone = false">
            {{ $t("general.noThanks") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDemoCheckoutDone = false">{{
            $t("general.rateNow")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" v-model="showShopClosedMessage">
      <v-card v-if="showShopClosedMessage">
        <v-card-title>
          {{ $t("shop.shopClosedMessageTitle") }}
        </v-card-title>
        <v-card-text
          v-html="
            $t('shop.shopClosedMessage', {
              opening_time: $store.state.shop.info.opening_time,
              closing_time: $store.state.shop.info.closing_time,
            })
          "
        >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showShopClosedMessage = false">{{
            $t("modal.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <player></player>
    <v-footer
      v-show="show"
      app
      height="auto"
      v-if="
        !userMenuOpen &&
        mainMenu.length > 0 &&
        $route.name !== 'login' &&
        playingStation
      "
    >
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout justify-center algin-center>
            <v-list-item v-if="playingStation" style="width: 100%">
              <v-list-item-avatar tile>
                <v-img
                  contain
                  :src="$config.managerMedia + playingStation.image"
                  :lazy-src="$config.managerMedia + playingStation.image"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ playingStation.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  @click.stop.prevent.native="setPlayingStation(undefined)"
                  >mdi-close</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-footer>

    <bottom-navigation
      v-if="$route.name !== 'login' && initialized"
      :style="{
        'z-index': 10,
      }"
    ></bottom-navigation>
    <v-snackbar
      :timeout="-1"
      v-model="addToHomescreenSnackbar"
      bottom
      multi-line
    >
      {{
        $t("general.addToHomescreen", {
          title: theme.title,
        })
      }}
      <v-btn
        color="primary"
        text
        @click="
          addToHomescreenSnackbar = false;
          deferredPrompt.prompt();
        "
        >OK</v-btn
      >
      <v-btn icon @click="addToHomescreenSnackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <core-dialog></core-dialog>
    <v-fab-transition>
      <v-btn
        fixed
        v-show="magicButtonShow"
        :style="'left: ' + magicLeft + '%; bottom: ' + magicBottom + '%;'"
        x-large
        icon
        @click="goToDDS()"
      >
        <v-icon size="300" color="#f5ff00" style="opacity: 0.7"
          >mdi-star</v-icon
        >
        <span style="position: absolute">
          Wanna
          <br />get <br />flashed?
        </span>
      </v-btn>
    </v-fab-transition>
    <QrCodeReader></QrCodeReader>
  </v-app>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getModuleRoute, getRoute, getRouteIcon } from "@/util/routes";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import isFunction from "lodash/isFunction";
import Player from "@/components/Player";
import QrCodeReader from "@/components/QrCodeReader";
import LanguageSelection from "@/components/LanguageSelection";
import { ROOT } from "@/config";
import SignedInUserListTile from "@/components/SignedInUserListTile";
import CheckedInPersonAvatar from "@/components/CheckedInPersonAvatar";
import BottomNavigation from "@/components/core/BottomNavigation";
import CoreDialog from "@/components/core/Dialog";
import LinkPrevue from "link-prevue";
import isUrl from "is-url";
import { goTo } from "@/util/contentpage";
import detectBrowserLanguage from "detect-browser-language";

export default {
  name: "app",
  data: function () {
    return {
      rating: 0,
      open: false,
      tab: undefined,
      drawer: false,
      offsetTop: 0,
      showScrollTopButton: false,
      landingPage: undefined,
      videoPlayer: false,
      playback: false,
      deferredPrompt: undefined,
      addToHomescreenSnackbar: false,
      root: ROOT,
      bottomNav: undefined,
      chatbotAppId: undefined,
      chatbotConversationTitle: undefined,
      chatbotEnable: false,
      chatbotInitialized: false,
      options: {
        highlight: true,
        labels: {
          buttonSkip: this.$t("tour.skip"),
          buttonPrevious: this.$t("tour.prev"),
          buttonNext: this.$t("tour.next"),
          buttonStop: this.$t("tour.finish"),
        },
      },
    };
  },
  components: {
    player: Player,
    LanguageSelection,
    SignedInUserListTile,
    CheckedInPersonAvatar,
    BottomNavigation,
    CoreDialog,
    LinkPrevue,
    QrCodeReader,
  },
  created() {
    const _this = this;
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      _this.deferredPrompt = e;
    });

    this.setCartItemListFromLocalStorage();
  },
  mounted() {
    this.calculateMediaHeight({
      breakpoint: this.$vuetify.breakpoint,
    });
  },
  updated() {
    if (this.$route.name === "home") {
      this.home();
    }
  },
  computed: {
    whiteToolbar() {
      const { toolbarBackground = "" } = this.theme;
      return (
        toolbarBackground === "white" ||
        toolbarBackground === "#FFFFFF" ||
        toolbarBackground === "#ffffff" ||
        toolbarBackground === "#FFF" ||
        toolbarBackground === "#fff"
      );
    },
    showDemoCheckoutDone: {
      get() {
        return this.$store.state.showDemoCheckoutDone;
      },
      set(val) {
        return this.$store.commit("SET_SHOW_DEMO_CHECKOUT_DONE", val);
      },
    },
    showCheckoutConfirmation: {
      get() {
        return this.$store.state.showCheckoutConfirmation;
      },
      set(val) {
        return this.$store.commit("SET_SHOW_CHECKOUT_CONFIRMATION", val);
      },
    },
    showShopClosedMessage: {
      get() {
        return this.$store.state.shop.showShopClosedMessage;
      },
      set(val) {
        return this.$store.commit("shop/SET_SHOW_SHOP_CLOSED_MESSAGE", val);
      },
    },
    show() {
      return this.themeStatus === "success";
    },
    menu() {
      const _this = this;
      return [
        this.isWebcheckinEnabled &&
        !this.disableCheckout &&
        this.mode !== "permanent-only" &&
        this.mode !== "walkin" &&
        this.mode !== "walkin-only"
          ? {
              icon: "mdi-lock-open",
              click() {
                _this.showCheckoutConfirmation = true;
              },
              title: _this.$t("login.checkout"),
            }
          : undefined,
        this.authResult.checkin &&
        this.mode !== "permanent-only" &&
        this.mode !== "walkin" &&
        this.mode !== "walkin-only"
          ? {
              icon: "mdi-exit-to-app",
              click() {
                _this.logout();
              },
              title: this.$t("login.logout"),
            }
          : this.mode !== "walkin-only" && this.mode !== "permanent-only"
          ? {
              icon: "mdi-lock-open",
              click() {
                _this.deleteCookies();
                _this.deleteLoginData();
                _this.resetSession();
              },
              title: this.$t("login.signin"),
            }
          : undefined,
      ];
    },
    steps() {
      switch (this.$route.path) {
        case "/demo/room-control":
          let retVal = [];

          /* If room controls are available, activate tutorial for them */
          if (this.controls > 0 || Object.keys(this.temp).length !== 0) {
            for (let x = 0; x < 6; x++) {
              retVal[x] = {};
              retVal[x].target = `[data-v-step='roomctrl_${x}']`;
              retVal[x].content = this.$t(`tour.roomControl.step${x + 1}`);
              retVal[x].offset = -400;
            }
            /* Disable tutorial for room controls if they are not available. */
          } else {
            for (let x = 0; x < 5; x++) {
              retVal[x] = {};
              retVal[x].target = `[data-v-step='roomctrl_${x + 1}']`;
              retVal[x].content = this.$t(`tour.roomControl.step${x + 2}`);
            }
          }

          return retVal;
        case "/demo/mobilekey":
          return [
            {
              target: '[data-v-step="key_0"]',
              content: this.$t("tour.mobileKey.step1"),
            },
          ];
        case "/remote-control":
          return [
            {
              target: '[data-v-step="remote_0"]',
              content: this.$t("tour.remoteControl.step1"),
            },
          ];
        case "/tv-message":
          return [
            {
              target: '[data-v-step="tvmsg_0"]',
              content: this.$t("tour.tvMessage.step1"),
              offset: -500,
            },
            {
              target: '[data-v-step="tvmsg_1"]',
              content: this.$t("tour.tvMessage.step2"),
              offset: -300,
              params: {
                placement: "bottom",
              },
            },
          ];
        /* FIX to browse tutorial steps would sometimes not load */
        case "/vod/browse":
        case "/vod/browse/":
          return [
            {
              target: '[data-v-step="browse_0"]',
              content: this.$t("tour.browse.step1"),
            },
            {
              target: '[data-v-step="browse_1"]',
              content: this.$t("tour.browse.step2"),
              offset: -300,
            },
            {
              target: '[data-v-step="browse_2"]',
              content: this.$t("tour.browse.step3"),
            },
          ];
        case "/vod/packages":
          return [
            {
              target: '[data-v-step="packages_0"]',
              content: this.$t("tour.packages.step1"),
            },
            {
              target: '[data-v-step="packages_1"]',
              content: this.$t("tour.packages.step2"),
            },
          ];
        case "/demo/voip":
          return [
            {
              target: '[data-v-step="voip_0"]',
              content: this.$t("tour.voip.step1"),
            },
            {
              target: '[data-v-step="voip_1"]',
              content: this.$t("tour.voip.step2"),
            },
          ];
        case "/epg":
          return [
            {
              target: '[data-v-step="epg_0"]',
              content: this.$t("tour.epg.step1"),
              offset: -300,
            },
            {
              target: '[data-v-step="epg_1"]',
              content: this.$t("tour.epg.step2"),
              offset: -300,
            },
          ];
        case "/webradio":
          return [
            {
              target: '[data-v-step="radio_0"]',
              content: this.$t("tour.webradio.step1"),
              offset: -300,
            },
            {
              target: '[data-v-step="radio_1"]',
              content: this.$t("tour.webradio.step2"),
              offset: -300,
            },
            {
              target: '[data-v-step="radio_2"]',
              content: this.$t("tour.webradio.step3"),
              offset: -300,
            },
            {
              target: '[data-v-step="radio_3"]',
              content: this.$t("tour.webradio.step4"),
              offset: -300,
            },
            {
              target: '[data-v-step="radio_4"]',
              content: this.$t("tour.webradio.step5"),
              offset: -300,
            },
          ];
        case "/search":
          return [
            {
              target: '[data-v-step="search_0"]',
              content: this.$t("tour.search.step1"),
            },
            {
              target: '[data-v-step="search_1"]',
              content: this.$t("tour.search.step2"),
            },
          ];
        case "/flightinfo":
          return [
            {
              target: '[data-v-step="flight_0"]',
              content: this.$t("tour.flight.step1"),
              offset: -300,
            },
            {
              target: '[data-v-step="flight_1"]',
              content: this.$t("tour.flight.step2"),
              offset: -300,
            },
            {
              target: '[data-v-step="flight_2"]',
              content: this.$t("tour.flight.step3"),
              offset: -300,
            },
          ];
      }
    },
    tourAvailable() {
      let retVal = false;

      switch (this.$route.path) {
        case "/epg":
          retVal =
            /* If there are no channels available => disable help  */
            this.steps !== undefined && this.$store.state.epg.allChannels;
          break;
        case "/tv-message":
          retVal =
            /* If there are no tv-messages => disable help */
            this.steps !== undefined && this.$store.state.tvmessage.items;
          break;
        case "/webradio":
          /* If radio list empty => disable help */
          retVal =
            this.steps !== undefined &&
            this.$store.state.webradio.selectedCountry.radio_stations;
          break;
        case "/vod/browse/":
          /* If movie list empty => disable help */
          retVal = this.steps !== undefined && this.allMovies.total > 0;
        default:
          retVal = this.steps !== undefined;
      }
      return retVal;
    },
    ...mapState({
      initialized: ({ session }) => session.initialized,
      isCheckedIn: ({ session }) => session.isCheckedIn,
      mode: ({ session }) => session.mode,
      settings: ({ session }) => session.settings,
      hideUi: ({ player }) => player.hideUi,
      skinData: (state) => state.session.skinData,
      authResult: ({ session }) => session.authResult,
      authStatus: ({ session }) => session.status,
      data: ({ weather }) => weather.data,
      sessionStatus: ({ session }) => session.status,
      status: ({ weather }) => weather.status,
      allMovies: ({ vod }) => vod.allMovies,
      theme: ({ theme }) => theme.theme,
      playingStation: ({ webradio }) => webradio.playingStation,
      themeStatus: ({ theme }) => theme.status,
      remoteControlStatus: ({ remoteControl }) =>
        remoteControl.statusRequestTvs,
      isWebcheckinEnabled: ({ session }) => session.isWebcheckinEnabled,
      disableCheckout: ({ session }) =>
        session.settings.webcheckin.disableCheckout,
      propertyId: ({ session }) => session.propertyId,
      themeId: ({ session }) => session.themeId,
      magicLeft: ({ session }) => session.magicLeft,
      magicBottom: ({ session }) => session.magicBottom,
      magicButtonInterval: ({ session }) => session.magicButtonInterval,
      magicButtonShow: ({ session }) => session.magicButtonShow,
      selectedLanguage: ({ language }) => language.selectedLanguage,
      landingPageId: (state) => state.landingPageId,
      showUserMenu: (state) => state.showUserMenu,
      playVideoStatus: ({ appplayer }) => appplayer.status,
      showSchedule: ({ roomservice }) => roomservice.showSchedule,
      controls: ({ roomservice }) => roomservice.controls,
      temp: ({ roomservice }) => roomservice.temp,
    }),
    mainMenu() {
      let mainMenu = [];
      each(this.skinData.menu, function (menuItem) {
        menuItem.active = true;
        mainMenu.push(menuItem);
      });
      if (mainMenu.length > 0) {
        this.landingPage = mainMenu[0];
      }
      return mainMenu;
    },
    userMenuOpen: {
      get() {
        return this.showUserMenu;
      },
      set(val) {
        this.setShowUserMenu(val);
      },
    },
  },
  methods: {
    goTo,
    isUrl,
    ...mapActions("session", ["goToWebcheckout", "goToDDS", "initMagicButton"]),
    ...mapMutations({ setLandingPage: "SET_LANDING_PAGE" }),
    ...mapMutations({ setShowUserMenu: "SET_SHOW_USER_MENU" }),
    ...mapMutations("modal", {
      resetModal: "RESET",
    }),
    ...mapMutations("session", {
      resetSession: "RESET",
    }),
    ...mapMutations("webradio", {
      setPlayingStation: "SET_PLAYING_STATION",
    }),
    ...mapMutations("remoteControl", {
      setRoomNr: "SET_ROOM_NR",
      setSelectedTv: "SET_SELECTED_TV",
      setSpecialDevicesCalled: "SET_SPECIAL_DEVICES_CALLED",
    }),
    ...mapActions("modal", ["showDirectplay"]),
    ...mapActions("vod", ["setShowFilter"]),
    ...mapActions("session", ["setMode", "deleteLoginData"]),
    ...mapActions(["calculateMediaHeight"]),
    ...mapActions("tvmessage", ["getTvMessages"]),
    ...mapActions("shop", ["setCartItemListFromLocalStorage"]),
    ...mapActions("remoteControl", ["getTvs", "sendCmd"]),
    ...mapActions("language", ["deleteCookies", "getLanguageList"]),
    initData() {
      this.getTvMessages();
      this.setRoomNr(this.authResult.room);
      this.setSpecialDevicesCalled(this.$config.remove_special_devices_called);
      this.getTvs();
    },
    getRoute,
    getRouteIcon,
    home() {
      this.drawer = false;
      if (this.sessionStatus === "registered") {
        this.$router.push(
          this.getRoute({
            item: this.landingPage,
          })
        );
      }
    },
    logout() {
      this.$ga.event({
        eventCategory: "Logout",
        eventAction: "clicked",
        eventLabel: `clicked`,
        eventValue: 1,
      });
      this.deleteCookies();
      this.deleteLoginData();
      this.resetSession();
      this.$router.push({ query: { autologin: undefined } });
      setTimeout(() => {
        window.location.reload();
      }, 0);
    },
    onScroll(e) {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      this.showScrollTopButton =
        this.offsetTop > this.$vuetify.breakpoint.height / 2;
    },
    scrollToTop() {
      let el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    initConciergeChat() {
      if (
        this.chatbotEnable &&
        !this.chatbotInitialized &&
        this.$route.params.cpId === this.landingPageId
      ) {
        this.chatbotInitialized = true;
        var kommunicateSettings = {
          appId: this.chatbotAppId,
          userName: this.$route.query.name + " - " + this.$route.query.room,
          conversationTitle: this.chatbotConversationTitle,
          automaticChatOpenOnNavigation: true,
          onInit: () => {
            if (this.mode === "demo" || this.mode === "demo-use-pms") {
              Kommunicate.updateUserLanguage("en-GB");
            } else {
              Kommunicate.updateUserLanguage(detectBrowserLanguage());
            }
          },
        };

        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://api.kommunicate.io/v2/kommunicate.app";
        var h = document.getElementsByTagName("head")[0];
        h.appendChild(s);
        window.kommunicate = window.kommunicate || {};
        window.kommunicate._globals = kommunicateSettings;
      }
    },
    activateSchedulerSwitch(val) {
      if (!this.showSchedule) {
        this.$store.commit("roomservice/TOGGLE_SCHEDULE");
      }

      /* Workaround -> v-tour should wait for switch to be activated before displaying tutorial message */
      setTimeout(() => {
        val();
      }, 0);
    },
    startTour() {
      this.$tours["myTour"].start();
      this.$ga.event({
        eventCategory: "Tutorial",
        eventAction: "start",
        eventLabel: `Started Vue-Tour on route ${this.$route.name}`,
        eventValue: 1,
      });
    },
  },
  watch: {
    playVideoStatus(val) {
      const kommunciateWidget = document.querySelector(
        "#kommunicate-widget-iframe"
      );
      if (kommunciateWidget) {
        kommunciateWidget.style.display = val === "play" ? "none" : "block";
      }
    },

    deferredPrompt(val) {
      if (isFunction(val)) {
        this.addToHomescreenSnackbar = true;
      }
    },
    drawer() {
      this.userMenuOpen = false;
    },
    landingPageId(newVal, oldVal) {
      if (this.$route.params.cpId === oldVal) {
        this.home();
      }
    },
    landingPage(val) {
      this.setLandingPage(val);
      if (this.$route.name === "home") {
        this.home();
      }
      this.initConciergeChat();
    },
    "$route.path"(val) {
      /* Stop tour if path changed */
      this.$tours["myTour"].stop();

      const kommunciateWidget = document.querySelector(
        "#kommunicate-widget-iframe"
      );
      if (kommunciateWidget) {
        kommunciateWidget.style.display =
          this.$route.params.cpId === this.landingPageId ? "block" : "none";
      }
      this.initConciergeChat();
    },
    "$route.name"(val) {
      this.$store.commit("session/RESET_MAGIC_BUTTON");
      if (val === "home" || val === "contentpage") {
        if (this.$route.query.enableDDS === "true") {
          this.$store.dispatch("session/initMagicButton");
        }
      }

      if (val === "home") {
        this.home();
      } else if (val === "login") {
        this.$store.commit("SET_LOADING", false);
      }
    },
    "$route.query"() {
      if (
        this.$route.query.opendoor &&
        this.$store.state.session.status === "registered"
      ) {
        this.$store.dispatch("onekey/openDoor", {
          roomNumber: this.$route.query.opendoor,
        });
      }

      this.setMode({});
    },
    initialized() {
      this.initData();
    },
    "$store.state.loading"() {
      if (!this.initialized && this.$route.name !== "login") {
        this.$store.commit("SET_LOADING", true);
      }
    },
    authResult(newVal, oldVal) {
      this.getLanguageList();
      if (!isEmpty(oldVal)) {
        this.$router.push({ name: "home" });
      }
    },
    mainMenu() {
      if (this.authStatus === "registered") {
        this.$router.push({ name: "home" });
      }
    },
    "$vuetify.breakpoint.name"() {
      this.calculateMediaHeight({
        breakpoint: this.$vuetify.breakpoint,
      });
    },
    mode(val) {
      if (val === "demo" || val === "demo-use-pms") {
        this.chatbotAppId = "68fc47ad7459ca6fa22089655c91adad";
        this.chatbotConversationTitle = "Concierge";
        this.chatbotEnable = true;
        this.initConciergeChat();
      }
    },
    theme(val) {
      this.$vuetify.theme.themes.light.primary = val.primary;
      this.$vuetify.theme.themes.dark.primary = val.primary;
      this.$vuetify.theme.isDark = val.dark;
    },
    settings({ chatBot = {} }) {
      this.chatbotAppId = chatBot.appId;
      this.chatbotConversationTitle = chatBot.conversationTitle;
      this.chatbotEnable = chatBot.enable;
      this.initConciergeChat();
    },
  },
};
</script>

<style lang="scss">
#kommunicate-widget-iframe {
  bottom: 64px;
}

/* Custom v-tour style */
.v-step {
  /* Change 'background' value to the desired tour color */
  background: var(--v-accent-darken4) !important;
  color: white;
  max-width: 320px;
  border-radius: 3px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  padding: 1rem;
  text-align: center;
  z-index: 10000;
}
.v-step .v-step__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 0.5rem;
  /* Change border-bottom-color to the desired tour color */
  border-bottom-color: var(--v-accent-darken4) !important;
}

/* Custom v-tour button style */
.v-step__button {
  background: transparent;
  border: 0.05rem solid var(--v-primary-base) !important;
  border-radius: 0.1rem;
  color: var(--v-primary-base) !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  height: 1.8rem;
  line-height: 1rem;
  outline: none;
  margin: 0 0.2rem;
  padding: 0.35rem 0.4rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  vertical-align: middle;
  white-space: nowrap;
  &:hover {
    background-color: rgba(white, 0.95) !important;
    color: var(--v-primary-base) !important;
  }
}

/* v-tour custom highlight */
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}

.app-bar-white {
  border-width: 0 0 thin 0;
  border-style: solid;
  &.theme--light {
    border-bottom-color: #0000001f !important;
    background-color: #ffffff !important;
  }
  &.theme--dark {
    border-bottom-color: #ffffff1f !important;
  }
}
</style>
